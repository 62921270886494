<div class="login-container">
    <div class="col s-100 panel-left">
        <div class="banner">
            <img class="bg" src="assets/brand-logo.png">
        </div>
    </div>
    <div class="col s-100 panel-right">
        <div class="form-wrapper animate fade-in">
            <app-heading [title]="'Register'"></app-heading>
            <form class="form" (submit)="signup($event)" [formGroup]="signUpForm">
                <app-alert *ngIf="showError" [type]="'danger'">
                    <div content>{{showError}}</div>
                </app-alert>
                <app-alert *ngIf="showSuccess">
                    <div content>Account successfully created</div>
                </app-alert>
                <div class="row">
                    <div class="split">
                        <app-form-input [label]="'First Name'" [error]="signUpForm.controls.firstName">
                            <input type="text" formControlName="firstName">
                        </app-form-input>
                    </div>
                    <div class="split">
                        <app-form-input [label]="'Last Name'" [error]="signUpForm.controls.lastName">
                            <input type="text" formControlName="lastName">
                        </app-form-input>
                    </div>
                </div>
                <app-form-input [label]="'Email'" [error]="signUpForm.controls.email">
                    <input type="email" formControlName="email">
                </app-form-input>
                <!-- <app-form-input [label]="'Phone'" [error]="signUpForm.controls.phone">
                    <input type="text" formControlName="phone">
                </app-form-input> -->
                <!-- <app-form-input class="input" [type]="'phone'" [label]="'Phone'" [error]="signUpForm.controls.phone"
                    (change)="setPhone($event)">
                </app-form-input> -->
    
                    <div class="container">
                        <div style="position: absolute;right: 10px;margin-top: 38px;z-index: 1;" >
                            <mat-icon (click)="togglePasswordVisibility()" style="cursor: pointer;">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>          
                         </div>
                        <div>
                <app-form-input [label]="'Password'" [error]="signUpForm.controls.password">
                    <input type="{{ hidePassword ? 'password' : 'text' }}" formControlName="password" autocomplete="off">
                   </app-form-input>
            </div>
           
            </div>
            <div *ngIf = "signUpForm.get('password').hasError('pattern')" class = "alert-danger">
                <span style="color:red;font-size: 12px;">minimum 8 characters, with at least 1 number, 1 capital letter and 1 special character</span>
              </div>
                  <div class="container">
                    <div>
                <app-form-input [label]="'Confirm Password'" [error]="signUpForm.controls.confirmPassword">
                    <input type="{{ hideConfirmPassword ? 'password' : 'text' }}" formControlName="confirmPassword" autocomplete="off">
                </app-form-input>
            </div>
            <div style="position: absolute;right: 10px;bottom:210px;">
                <mat-icon (click)="toggleConfirmPasswordVisibility()" style="cursor: pointer;">{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>          
             </div>
            </div>
                <div class="terms-check">
                    <app-form-checkbox [value]="signUpForm.value.acceptTerms" (change)="setTerms($event)">
                    </app-form-checkbox>
                    <div class="plabel">I accept the <a href="https://quicktest.ai/terms-and-conditions/"
                            target="_BLANK">Terms of
                            Service</a> and have read the <a href="https://quicktest.ai/privacy-policy-2/"
                            target="_BLANK">Privacy
                            Statement</a>.
                    </div>
                </div>
                <button class="hide">signup</button>
                <div class="action-bar">
                    <button class="btn-create" style="margin-right: 0px;">Register</button>
                </div>
                <div class="login-link">
                    Already have an account? <a routerLink="/login">Sign In</a>
                </div>
            </form>
        </div>
    </div>
</div>