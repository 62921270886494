import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-matconfirmdialog',
  templateUrl: './matconfirmdialog.component.html',
  styleUrls: ['./matconfirmdialog.component.scss']
})
export class MatconfirmdialogComponent implements OnInit {
 
  public confirmMessage:string;
  public title:string
  constructor(public dialogRef: MatDialogRef<MatconfirmdialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    console.log(this.data)
    this.confirmMessage=this.data.confirmmessage
    this.title=this.data.title
  }

  ngOnInit() {
    
  }

}
