
<div class="dialog-content">

    <div class="modal-header">
      <!-- <h4 class="modal-title">{{title}}</h4> -->
      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
  
    <mat-dialog-content>
      <div class="modal-body" style="text-align: center;font-size: 17px;color: black;">
        {{confirmMessage}}
        
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions class="modal-footer" align="end">
    
      <!-- <app-button type="submit" [size]="'sm'"  (click)="dialogRef.close(true)">Yes</app-button>
      <app-button type="button"[size]="'sm'"  (click)="dialogRef.close(false)">No</app-button> -->
      <div class="action-bar" >
        <button class="btn-create" style="color: white;margin-left: 15px;"  (click)="dialogRef.close(true)"> Yes</button>
    </div>
    <div class="action-bar" >
      <button class="btn-create" style="color: white;"   (click)="dialogRef.close(false)"> No</button>
  </div>
    </mat-dialog-actions>
  
  </div>