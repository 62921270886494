import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() total = 0;
  @Input() limit = 10;
  @Output() change = new EventEmitter<number>();

  totalPages = 0;
  pages = [];
  activePage = 0;
  totalVisiblePages = 5;

  constructor() {}

  ngOnInit() {
    this.calculateTotalPages();
    this.updatePages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.total) {
      this.calculateTotalPages();
      this.updatePages();
      // Maintain the active page unless it's out of range
      if (this.activePage >= this.totalPages) {
        this.activePage = this.totalPages - 1;
      }
      this.change.emit(this.activePage); // Emit the current page
    }
  }

  calculateTotalPages() {
    this.totalPages = Math.ceil(this.total / this.limit);
  }

  updatePages() {
    this.pages = [];
    for (let i = 0; i < this.totalVisiblePages && this.pages.length < this.totalPages; i++) {
      this.pages.push(i);
    }
  }

  goto(page: number) {
    if (page < 0 || page >= this.totalPages) return; // Ensure the page is within valid range
    this.activePage = page;
    this.updatePages(); // Update visible pages
    this.change.emit(this.activePage); // Emit the change to parent
  }

  gotoFirst() {
    this.goto(0);
  }

  gotoLast() {
    this.goto(this.totalPages - 1);
  }

  gotoBack() {
    this.goto(this.activePage - 1);
  }

  gotoNext() {
    this.goto(this.activePage + 1);
  }
}
