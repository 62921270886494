
<div class="dialog-content">

    <div class="modal-header">
      <!-- <h4 class="modal-title">{{title}}</h4> -->
      <button type="button" class="btn-close" aria-label="Close" (click)="dialogRef.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <mat-dialog-content>
      <div class="modal-body" style="text-align: center;">
        {{confirmMessage}}
        
      </div>
    </mat-dialog-content>
  
    <mat-dialog-actions class="modal-footer" align="end">
      <app-button type="button"[size]="'sm'" class="btn btn-cancel" (click)="dialogRef.close(false)">Cancel</app-button>
      <app-button type="submit" [size]="'sm'" class="btn btn-save" (click)="dialogRef.close(true)">Confirm</app-button>
    </mat-dialog-actions>
  
  </div>