<div class="mwa-date-picker">
  <div class="overlay-wrapper" *ngIf="showPicker" (click)="close(true)"></div>
  <div class="wrapper" *ngIf="showPicker">
    <div class="box-name" *ngIf="title">{{ title }}</div>
    <div class="selector" *ngIf="allowTime">
    
      <div class="item" [class.active]="mode===0" >
        <i-feather name="clock" class="ico">
        </i-feather>Time
      </div>
    </div>
    <div class="date-picker">
    <!-- date ends -->
    <div class="time-picker" *ngIf="mode === 0" [formGroup]="setTimeForm">
      <div class="time-preview">
        {{ (setTimeForm.value.timeHr+setTimeForm.value.timeZone).length === 1? 0+''+setTimeForm.value.timeHr: setTimeForm.value.timeHr+setTimeForm.value.timeZone }}:{{ setTimeForm.value.timeMin.length===1? 0+''+setTimeForm.value.timeMin: setTimeForm.value.timeMin }}
      </div>
      <div class="set-hr">
        <div class="label">Hours:</div>
        <mat-slider class="mwa-slider w-100" [max]="11" [min]="0" [step]="1" formControlName="timeHr"></mat-slider>
      </div>
      <div class="set-min">
        <div class="label">Minutes:</div>
        <mat-slider class="mwa-slider w-100 slide" [max]="59" [min]="0" [step]="1" formControlName="timeMin"></mat-slider>
      </div>
      <div class="set-zone">
        <div class="label-set">
          <div class="label">AM</div>
          <div class="label">PM</div>
        </div>
        <mat-slider class="mwa-slider slide" [max]="12" [min]="0" [step]="12" formControlName="timeZone">
        </mat-slider>
      </div>
      <div class="save">
        <button mat-flat-button (click)="close()">Save</button>
      </div>
    </div>
  </div>
</div>