import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Auth } from 'aws-amplify';
import { switchMap ,map} from 'rxjs/operators';
import { Observable, interval, Subscription } from 'rxjs';
import { requestsService } from '../services/requests/requests.service';
import { UserQuery } from '../services/user/user.query';
import { DomSanitizer } from '@angular/platform-browser';
import {CookieService} from 'ngx-cookie'
import { ToastrService } from 'ngx-toastr';
import {GenesystokenService} from '../services/genesystoken.service';
import { Config } from '../config';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('viewDOM') viewDOM: ElementRef;
 
  //readmore variable, its true than read more string will print
  ReadMore:boolean = true
  //hiding info box
  visible:boolean = false
  OneDesignVisible:boolean=false
  settingWidth:boolean=false
  contractSidebar: boolean;
  user: any;
  uservalue:any
  thumbnail:any;
  modules:any=null;
  integrationsApi:any=null;
  Membershipres:any=[]
  onechangeSubitem:boolean=false
  roleList:any=[]
  showManagement:boolean=false
  @Input() genesysUserexists:any;
  availableFeatures = {
    OneTrack: false,
    oneChange: false,
    oneTrackAdvanced:false
  }

  navList = [
    {
      name: 'OneTrack Lite',
      route: 'dashboard'
    },
    {
      name: 'OneTrack Advanced',
      route: 'one-change-advance'
    },
    {
      name: 'OneChange',
      route: 'one-change',
      children:[
        {
          name: 'Package Group',
          icon: 'dashboard.svg',
          iconSelected: 'dashboard-light.svg',
          route: 'package-groupe'
        },
        {
          name: 'Package Report',
          icon: 'dashboard.svg',
          iconSelected: 'dashboard-light.svg',
          route: 'packagereport'
        },
        {
          name: 'Flow Promote',
          icon: 'dashboard.svg',
          iconSelected: 'dashboard-light.svg',
          route: 'flowPromote'
        }
      ]
    },
    // {
    //   name: 'OneDesign',
    //   route: 'one-design',
    //   children:[
    //     {
    //       name: 'OneClone',
    //       route: 'copy-env'
    //     },
    //     {
    //       name: 'OneClone Report',
    //       route: 'copy-report'
    //     }
    //   ]
    // }
  ];

  activePage = 'dashboard';
  activeSection = 'dashboard'

  showUserMenu = false;
  onechange:boolean=false
  onechangePage:boolean=false
  onetrackAdvance:boolean=false
  flowPromote:boolean=false
  packagereport:boolean=false
  onetrack:boolean=false
  usermgmt:boolean=false
  gitdetails:boolean=false
  profilesection:boolean=false
  OneDesign:boolean=false
  copyEnv:boolean=false
 copyEnvReport:boolean=false
  usermanagement:boolean=false
  disasterRecovery:boolean=false
  memberShipPlan:string='';
  platformclient:any;
  oAuthApi:any=null;
  queryRes:any=[]
  constructor(
    private routerQuery: RouterQuery,
    private userQuery: UserQuery,
    private router: Router,
    private apiService: requestsService, private sanitizer: DomSanitizer,private cookie:CookieService,private config:Config,private toastr: ToastrService
    ,private geneSysService:GenesystokenService,) {

      if(this.geneSysService.tokenType=='aws'){
        this.userQuery.alreadyLogged().subscribe(user => {
          this.user = user;
        });
        this.apiService.getRoleListByUsername().subscribe(res=>{
         this.roleList=res
          if(this.roleList?.role?.roleName=='Admin'){
            this.showManagement=true
          }
          else{
            this.showManagement=false
          }
        })
        Auth.currentUserInfo().then(res => {
          if (res && res.attributes) {
            this.userQuery.setUser(res.attributes);
            this.loadProfileImg();
          } else {
            this.logout();
          }
        }, err => {
          this.logout();
        });
      }
      else{
        this.platformclient= (<any>window).platformClient;
        this.integrationsApi = new this.platformclient.IntegrationsApi();
        this.oAuthApi= new this.platformclient.OAuthApi();
        const usersApi = new this.platformclient.UsersApi();
        let opts = {'expand': ['authorization']};
        usersApi.getUsersMe(opts).then(res=>{
          this.user=res;
          this.geneSysService.setUser(res);
          this.loadProfileImg();
        }).catch((e) => {console.error(e);this.logout();}); 
   // return usersApi.getUsersMe(opts);
      }
      this.modules= [
        {roleModule:this.config.provisioningInfo.role,provisioningRoleInfoKey:'Role'}, 
        // {groupModule:this.config.provisioningInfo.group,provisioningGroupInfoKey:'Group'}, 
        {appInstanceModule:this.config.provisioningInfo.appinstance,provisioninagAppInstanceInfoKey:'app-instance'}, 
         {OAuthClientModule:this.config.provisioningInfo.oauthclient,provisioningOauthClientInfoKey:'oauth-client'},
        ];
   
  }

  ngOnInit(): void {
    this.MemberShipAPI()
    this.routerQuery.select(routerData => routerData.state).subscribe(state => {
      if(state.url=='/home/one-change/package-groupe'||state.url=='/home/one-change/packagereport'||state.url=='/home/one-change/flowPromote'){
        this.visible=true
        this.OneDesignVisible=false
      }
      if(state.url=='/home/one-design/copy-env'||state.url=='/home/one-design/copy-report'){
        this.visible=false
        this.OneDesignVisible=true
      }
      this.queryRes=state
      window.scrollTo(0, 0);
      const path = state.url.split('/');

      if (path.length > 2) {
        this.activeSection = path[2];
        if (path.length > 3) {
          this.activePage = path[3].split('?')[0];
        } else {
          this.activePage = path.reverse()[0];
        }
      }
     
      if(state.url==='/home'||state.url==='/home/dashboard'){
        this.onetrack=true
        this.onechangePage=false
        this.usermgmt=false
        this.gitdetails=false
        this.onechange=false
        this.usermanagement=false
        this.onetrackAdvance=false
        this.copyEnvReport=false
        this.flowPromote=false
        this.disasterRecovery=false
        this.packagereport=false
        this.profilesection=false
        this.OneDesign=false
        this.copyEnv=false
        //new added on 16.11.23
     
      }
      else if(state.url==='/home/one-change-advance'){
        this.onetrackAdvance=true
        this.onechangePage=false
        this.onetrack=false
        this.usermanagement=false
        this.usermgmt=false
        this.copyEnvReport=false
        this.gitdetails=false
        this.onechange=false
        this.flowPromote=false
        this.packagereport=false
        this.disasterRecovery=false
        this.profilesection=false
        this.OneDesign=false
        this.copyEnv=false
      }
      else if(state.url==='/home/user-mangement'){
        this.usermgmt=true
        this.onetrack=false
        this.onechangePage=false
        this.gitdetails=false
        this.copyEnvReport=false
        this.onechange=false
        this.usermanagement=false
        this.onetrackAdvance=false
        this.flowPromote=false
        this.disasterRecovery=false
        this.packagereport=false
        this.profilesection=false
        this.OneDesign=false
        this.copyEnv=false
      }
      else if(state.url==='/home/git-details'){
        this.gitdetails=true
        this.usermgmt=false
        this.onechangePage=false
        this.usermanagement=false
        this.onetrack=false
          this.onechange=false
          this.copyEnvReport=false
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.disasterRecovery=false
          this.profilesection=false
          this.OneDesign=false
          this.copyEnv=false
      }
      else if(state.url==='/home/one-change/flowPromote'){
        this.gitdetails=false
        this.usermanagement=false
        this.onechangePage=false
        this.copyEnvReport=false
        this.usermgmt=false
        this.onetrack=false
          this.onechange=false
          this.onetrackAdvance=false
          this.flowPromote=true
          this.packagereport=false
          this.disasterRecovery=false
          this.profilesection=false
          this.OneDesign=false
          this.copyEnv=false
        }
      else if(state.url==='/home/one-change/packagereport'){
        this.gitdetails=false
        this.copyEnvReport=false
        this.onechangePage=false
        this.usermgmt=false
        this.onetrack=false
        this.usermanagement=false
          this.onechange=false
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=true
          this.disasterRecovery=false
          this.profilesection=false
          this.OneDesign=false
          this.copyEnv=false
      }
      else if(state.url==='/home/one-change'){
        this.gitdetails=false
        this.copyEnvReport=false
        this.usermgmt=false
        this.onetrack=false
          this.onechange=false
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.usermanagement=false
          this.onechangePage=true
          this.disasterRecovery=false
          this.profilesection=false
          this.OneDesign=false
          this.copyEnv=false
      }
      else if(state.url==='/home/one-change/package-groupe'){
        this.gitdetails=false
        this.copyEnvReport=false
        this.usermgmt=false
        this.onetrack=false
        this.usermanagement=false
          this.onechange=true
          this.onetrackAdvance=false
          this.flowPromote=false
          this.disasterRecovery=false
          this.packagereport=false
          this.onechangePage=false
          this.profilesection=false
          this.OneDesign=false
          this.copyEnv=false
      }
      else if(state.url==='/home/user-management-section'||state.url==='/home/editUserProfile/'+this.queryRes?.root?.params?.id){
        this.gitdetails=false
        this.usermgmt=false
        this.copyEnvReport=false
        this.onetrack=false
          this.onechange=false
          this.disasterRecovery=false
          this.usermanagement=true
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.onechangePage=false
          this.profilesection=false
          this.OneDesign=false
          this.copyEnv=false
      }
      else if(state.url==='/home/disaster-recovery'){
        this.copyEnv=false
        this.gitdetails=false
        this.copyEnvReport=false
        this.usermgmt=false
        this.onetrack=false
          this.onechange=false
          this.usermanagement=false
          this.disasterRecovery=true
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.onechangePage=false
          this.profilesection=false
          this.OneDesign=false
      }
      else if(state.url==='/home/profile-section'){
        this.profilesection=true
        this.OneDesign=false
        this.copyEnvReport=false
        this.copyEnv=false
        this.gitdetails=false
        this.usermgmt=false
        this.onetrack=false
          this.onechange=false
          this.usermanagement=false
          this.disasterRecovery=false
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.onechangePage=false
      }
      else if(state.url==='/home/one-design'){
        this.OneDesign=true
        this.copyEnv=false
        this.copyEnvReport=false
        this.profilesection=false
        this.gitdetails=false
        this.usermgmt=false
        this.onetrack=false
          this.onechange=false
          this.usermanagement=false
          this.disasterRecovery=false
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.onechangePage=false
      }
      else if(state.url==='/home/one-design/copy-env'){
        this.OneDesign=false
        this.copyEnvReport=false
        this.copyEnv=true
        this.profilesection=false
        this.gitdetails=false
        this.usermgmt=false
        this.onetrack=false
          this.onechange=false
          this.usermanagement=false
          this.disasterRecovery=false
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.onechangePage=false
      }
      else if(state.url==='/home/one-design/copy-report'){
        this.OneDesign=false
        this.copyEnvReport=true
        this.copyEnv=false
        this.profilesection=false
        this.gitdetails=false
        this.usermgmt=false
        this.onetrack=false
          this.onechange=false
          this.usermanagement=false
          this.disasterRecovery=false
          this.onetrackAdvance=false
          this.flowPromote=false
          this.packagereport=false
          this.onechangePage=false
      }
      else{
        this.copyEnv=false
        this.copyEnvReport=false
        this.profilesection=false
        this.usermanagement=false
        this.onechange=false 
        this.gitdetails=false
        this.usermgmt=false
        this.onetrack=false
        this.onetrackAdvance=false
        this.flowPromote=false
        this.packagereport=false
        this.onechangePage=true
        this.disasterRecovery=false

      }
    });
    if(this.geneSysService.tokenType=="aws"){
      // this.apiService.userPlanDetails().subscribe(res => {
      //   if (res) {
      //     this.memberShipPlan=res.membershipName
      //     this.userQuery.setCurrentPlan(res);
      //   }
  
      //   if (!res || res.id === 0 || res.id === '0') {
      //     this.router.navigateByUrl('/home/membership');
      //   }
      // });
  
      // active listner for plan changes. So we can update the feature availability
      this.userQuery.currentPlan().pipe(
        switchMap(res => {
          //this below return commented on 16/11/23
          return res

          //both line added on 16/11/23
          // console.log("beow return apiii")
          // return this.apiService.MembershipDetails();
        })
      ).subscribe((res: any) => {
        
        // this.availableFeatures.OneTrack = res.OneTrack;
        // this.availableFeatures.oneChange = res.oneChange;
        // this.availableFeatures.oneTrackAdvanced = res.oneTrackAdvanced;
      });
    }
   else{
    // this.apiService.userPlanDetails().subscribe(res => {
    //   if (res) {
    //     this.memberShipPlan=res.membershipName
    //     //this.userQuery.setCurrentPlan(res);
    //   }

    //   if (!res || res.id === 0 || res.id === '0') {
    //     this.router.navigateByUrl('/home/membership');
    //   }
    // });

    // this.apiService.getTestTypeForUser().subscribe((res: any) => {
    //   this.availableFeatures.AutomaticTesting = res.AutomaticTesting;
    //   this.availableFeatures.LoadTesting = res.LoadTesting;
    //   this.availableFeatures.ScheduledTesting = res.ScheduledTesting;
    // });
   }
  
    // this.myBrowser();
  }
MemberShipAPI(){
  this.apiService.AWSMembershipDetails().subscribe(res=>{
    this.Membershipres=res
this.availableFeatures.OneTrack = this.Membershipres.oneTrackLite;
  this.availableFeatures.oneChange = this.Membershipres.onechange;
  this.availableFeatures.oneTrackAdvanced = this.Membershipres.oneTrackAdvanced;

  })
}
  loadProfileImg() {
    // this.apiService.getProfileImg().subscribe(res => {
    //   console.log(res)
    //   if (res) {
    //     if(res.size==0){
    //       this.thumbnail ="../../assets/dummy-profile-pic.jpg"
    //       this.userQuery.setUserImg(this.thumbnail);
    //     }
    //     else{
    //       var unsafeImageUrl = URL.createObjectURL(res);
    //       this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    //       // var unsafeImageUrl = URL.createObjectURL(res.blob());
    //       // this.thumbnail = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    //       if(this.geneSysService.tokenType=='genesys'){
    //         this.geneSysService.setUserImg(res);
    //       }
    //       else{
    //         this.userQuery.setUserImg(res);
    //       }
           
    //     }
        
    //   }
    //   console.log('000')
    // }, err => {
    //   this.thumbnail ="../../assets/dummy-profile-pic.jpg"
    //   if(this.geneSysService.tokenType=='genesys'){
    //     this.geneSysService.setUserImg(this.thumbnail);
    //   }
    //   else{
    //     this.userQuery.setUserImg(this.thumbnail);
    //   }
    //   console.log(err)
    //   console.log('---')
    // });
  }

  toggleSidePanel() {
    this.viewDOM.nativeElement.classList.toggle('wide');
  }
    //onclick toggling both
    onclick()
    {
      this.visible = !this.visible
      this.OneDesignVisible=false
    }
    oneDesignlick(){
      this.OneDesignVisible= !this.OneDesignVisible
      this.visible = false
    }
    
    onclick1()
    {
      this.visible = false
      this.OneDesignVisible=false
    }
//   uninstall() {
//     let promiseArr = [];

//     this.modules.forEach((module) => {
//       console.log(module)
//         promiseArr.push(
//             module.delete()
//         );
//     });
//     window.location.href = `${this.config.wizardUriBase}?${this.config.genesysCloudEnvironmentQueryParam}=${this.config.defaultPcEnvironment}&${this.config.languageQueryParam}="${this.config.defaultLanguage}"`;

//     return Promise.all(promiseArr);
// }
selectMenu(route){
  // '/home/'+route;
    window.open('#/home/'+route)
    localStorage.setItem("tabBrowserService",'/home/'+route)
}
selectMenu1(route){
  // '/home/'+route;
    window.open('#/home/one-change/'+route)
    localStorage.setItem("tabBrowserService",'/home/one-change/'+route)
}
selectMenu2(route){
  // '/home/'+route;
    window.open('#/home/one-design/'+route)
    localStorage.setItem("tabBrowserService",'/home/one-design/'+route)
}
  userMenuToggler() {
    this.showUserMenu = !this.showUserMenu;
  }
  OnechangeItem(){
this.onechangeSubitem=true

  }
  navTo(url) {
    this.userMenuToggler();
    this.router.navigateByUrl(url);
  }
  closeDropdown() {
    if (this.showUserMenu == true) {
      this.showUserMenu = false;
    }else{
      return;
    }
  }
 // Hari Sir told me to remove this uninstall on 15/02/24
//   uninstall(){
//     let del_app = [];
//     console.log(this.geneSysService,this.config.provisioningInfo)
//     if(this.geneSysService.genesysUserexists.length>0){
//       const IdOfApp = this.geneSysService.genesysUserexists.filter((item: any) => item.name ==this.config.provisioningInfo.appinstance[0].name)[0].id;
//         console.log(IdOfApp)
//         del_app.push(this.integrationsApi.deleteIntegration(IdOfApp).then(res=>{
//           if(res){
//             window.location.reload();
//              this.router.navigateByUrl("/");
//           }
//         }));
//         console.log(del_app)
//       //  })
//     }
//     if(this.geneSysService.ClientGensysUser.entities.length>0){
//         const IdOfClient = this.geneSysService.ClientGensysUser.entities.filter((item: any) => item.name == this.config.provisioningInfo.appinstance[0].name)[0].id;
//         console.log(IdOfClient)
//         if(IdOfClient){
//         del_app.push(this.oAuthApi.deleteOauthClient(IdOfClient).then(res=>{
//           if(res){
//           this.router.navigateByUrl("/");
//           }
//         }));
//         }
//     }
//     console.log(this.geneSysService)
//     console.log(del_app)
//     return Promise.all(del_app);
//     // console.log(this.msgToSibling)
//     let promiseArr = [];
//     // this.modules.remove()
//     // this.modules.forEach((module) => {
//     //     // promiseArr.push(
//     //       this.modules.splice() 
//     //     // );
//     //     console.log(this.modules);
//     //   // promiseArr.forEach((item)=>{
//     //   //   console.log(item)
//     //   // })
//     //   console.log(promiseArr)
//     // });
//     // this.modules.splice(0,this.modules.length)
//     // console.log(this.modules)
//     // return Promise.all(this.modules);
// }

  logout() {
    localStorage.removeItem("UserCred");
    this.cookie.remove("startDate")
    this.cookie.remove("endDate")
    this.userQuery.logout();
  }
  showHidePanelFn() {
    this.contractSidebar = !this.contractSidebar;
  }
  closeNav(){
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("menuHeaderpanel").style.width = "100%";
    //document.getElementById("allMainPage").style.width = "100%";
    //this.settingWidth="routerletwidth"
    this.settingWidth=false
  }
  openNav(){
    document.getElementById("mySidenav").style.width = "300px";
    this.settingWidth=true
    document.getElementById("menuHeaderpanel").style.width = "80%";
    //this.settingWidth="opennavigationrouterwidth"
  }
}
