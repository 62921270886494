import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { UserQuery } from './services/user/user.query';
import {requestsService} from './services/requests/requests.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit{
  title = 'QuickTest';
  
  constructor(private router: Router,
    private user: UserQuery,private apiService: requestsService ) {
  }


  ngOnInit(): void {
    
   // main.setup();
  }
  
  doUnload() {
    // Clear session or do something
   // this.auth.getLogout();
}

}
