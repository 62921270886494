import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HeadingComponent } from './heading/heading.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormInputComponent } from './forms/form-input/form-input.component';
import { ButtonComponent } from './button/button.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { CardComponent } from './card/card.component';
import { DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { AlertComponent } from './alert/alert.component';
import { FormCheckboxComponent } from './forms/form-checkbox/form-checkbox.component';
import { FormSelectComponent } from './forms/form-select/form-select.component';
import { ModalComponent } from './modal/modal.component';
import { MwaDateComponent } from './mwa-date/mwa-date.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatconfirmdialogComponent } from './matconfirmdialog/matconfirmdialog.component';
import { MwaRadioComponent } from './mwa-radio/mwa-radio.component';
import { DatesComponent } from './dates/dates.component';
import { PaginationComponent } from './pagination/pagination.component';

import {MatRadioModule} from '@angular/material/radio';
import { NewmatconfirmdialogComponent } from './newmatconfirmdialog/newmatconfirmdialog.component'

@NgModule({
  declarations: [HeaderComponent, HeadingComponent, 
    FormInputComponent, 
    ButtonComponent, PageContainerComponent, CardComponent, CalendarComponent, PaginatorComponent, DropdownComponent, TimePickerComponent, DatePickerComponent, AlertComponent, FormCheckboxComponent, FormSelectComponent, ModalComponent,MwaDateComponent, MatconfirmdialogComponent, MwaRadioComponent,DatesComponent, PaginationComponent, NewmatconfirmdialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSliderModule,
  ],
  exports: [
    HeaderComponent,
    HeadingComponent,
    FormInputComponent,
    ButtonComponent,
    PageContainerComponent,
    CardComponent,
    PaginatorComponent,
    DropdownComponent,
    TimePickerComponent,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    DatePickerComponent,
    MwaDateComponent,
    
    AlertComponent,
    FormCheckboxComponent,
    FormSelectComponent,
    ModalComponent,
    DatesComponent,
    PaginationComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CommonComponentsModule { }
