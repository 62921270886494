import { Injectable,Input } from '@angular/core';
import { Observable,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenesystokenService {
  tokenType:string="genesys"
  token: any;
  _appname:any;
  _language:any;
  _profileImage:any;
  _userData:any;
  _alreadyLogged:boolean=false;
  _productAvailable:boolean=false;
  genesysUserexists:any;
  ClientGensysUser:any
  constructor() { }

  get dataToken(): any{
    return this.token;
  }

  @Input() set dataToken(val: any){
    this.token = val;
    console.log(this.token);
  }


 get getAppName():any {
    return this._appname;
  }

  @Input() set setAppName(appName:any) {
      this._appname=appName;
  }

  get getLanguage():any {
    return this._language;
  }

  @Input() set setLanguage(language:any) {
      this._language=language;
  }


  setUser(data) {
    this._userData=data;
}

setUserImg(img) {
  this._profileImage=img;
   
}
// setItem(){
//   this.genesysUserexists
// }
// currentPlan() {
//   return this.select(user => {
//       return user.plan;
//   });
// }

// setCurrentPlan(planId) {
//   this.store.update(state => {
//       return {
//           ...state,
//           plan: planId
//       }
//   });
// }
}
