import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-schedul-time-picker',
  templateUrl: './schedul-time-picker.component.html',
  styleUrls: ['./schedul-time-picker.component.scss']
})
export class SchedulTimePickerComponent implements OnInit {
  @Input() height: string = 'auto'; // Default to 'auto' or specify a default height
  @Input() width: string = '100%'; // Default to '100%' or specify a default width
  @ViewChild('panelDOM') panelDOM: ElementRef;

  @Input() show = false;
  @Input() title = null;
  @Input() allowTime = false;
  @Input() disableInit = false;
  @Input() disableFutureDates = false;
  @Input() disablePastDates = false;
  @Output() change = new EventEmitter<any>();
  selection: string;
  month: string;
  day: string;
  year: number;
  today:number
  monthName: string;
  days = [];
  mode = 0;
  dated:any
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  showPicker = false;
  setTimeForm: FormGroup;
  constructor() {
    const date = new Date();
    this.setTimeForm = new FormGroup({
      timeHr: new FormControl(date.getHours() > 12 ? date.getHours() - 12 : date.getHours()),
      timeMin: new FormControl(date.getMinutes()),
      timeZone: new FormControl(date.getHours() > 12 ? 12 : 0),
    })
  }

  ngOnInit() {
    const date = new Date();
    this.day = ('0' + date.getDate()).substr(-2);
      this.month = ('0' + (date.getMonth() + 1)).substr(-2);;
    this.year = date.getFullYear();
    var futureDate =new Date(Date.now());
        //commented on 09/11/23
    // var fuDay = ('0' + (futureDate.getDate()+1)).substr(-2);
    //below line added on 09/11/23
    var fuDay = ('0' + (futureDate.getDate())).substr(-2)
    this.today = parseInt('' + this.year + this.month + fuDay);
    this.selection = this.day + '-' + this.month + '-' + this.year;
    if (!this.disableInit) {
      setTimeout(() => this.close());
    }
    var previousDate =new Date(Date.now() - 12096e5);
    const lastmonth=('0' + (date.getMonth())).substr(-2)
    const lastmonth2=('0' + (date.getMonth()+1)).substr(-2)
   
    var lastDay = ('0' + previousDate.getDate()).substr(-2);
    
    if(fuDay>='15'){

      this.dated = parseInt('' + this.year + lastmonth2 + lastDay);
    }
    else{
    
      this.dated = parseInt('' + this.year + lastmonth + lastDay);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show) {
      this.showPicker = changes.show.currentValue;
    }
    if (changes['date'] && changes.date.currentValue !== this.selection && !changes.date.firstChange) {
      const format = changes.date.currentValue.split('-');
      if (format.length > 0) {
        const old = format[1];
        format[1] = format[0];
        format[0] = old;
      }

      const date = new Date(format);
      this.day = ('0' + date.getDate()).substr(-2);
      this.month = ('0' + (date.getMonth() + 1)).substr(-2);;
      this.year = date.getFullYear();
      this.selection = this.day + '-' + this.month + '-' + this.year;
  
      // setTimeout(() => this.close());
    }
  }

  

 
  

  close(emit = true) {
    let time = '';
    console.log(emit)
    if (this.allowTime) {

      if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)>9) && this.setTimeForm.value.timeMin<10){
        time = ' ' + (this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone) + ':' + ('0'+this.setTimeForm.value.timeMin) + ':00';
      }
      else if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)<10) &&this.setTimeForm.value.timeMin>9){
        
        console.log(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)
        time = ' ' + ('0'+(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)) + ':' + this.setTimeForm.value.timeMin + ':00';
      }
      else if(((this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)<10) &&this.setTimeForm.value.timeMin<10){
   
        time = ' ' + ('0'+(this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone)) + ':' + ('0'+this.setTimeForm.value.timeMin) + ':00';
      }
      else{
       
        time = ' ' + (this.setTimeForm.value.timeHr + this.setTimeForm.value.timeZone) + ':' + this.setTimeForm.value.timeMin + ':00';
      }
    
    }
    if (emit) {
      
      if ((parseInt('' + this.year + this.month + this.day) > this.today) ||((parseInt('' + this.year + this.month + this.day) < this.dated))) {
        this.change.emit( time);
      }
      
     else{
     
      this.change.emit( time);
     }
      
    } else {
      // this.change.emit(this.selection + time);
    }
    this.showPicker = false;
  }
}