import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { UserQuery } from '../services/user/user.query';
import {requestsService} from '../services/requests/requests.service'
//import {ApiClientClass} from 'purecloud-platform-client-v2';
import { Config } from '../config';
import {GenesystokenService} from '../services/genesystoken.service'
import {ReportsdetailsService} from '../services/reportsdetails.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showError: string = null;
  showSuccess = false;
  hide : boolean = true;
  hidePassword:boolean=true
  platformclient:any;
  pcEnvironment:any;
  pcLanguage:any; 
  clientApp:any=null;   
  constructor(private router: Router,
    private user: UserQuery,private apiService: requestsService,private config:Config,private genesysServie:GenesystokenService,private propService:ReportsdetailsService,private toastr: ToastrService) {
      this.platformclient= (<any>window).platformClient;
   
  
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(false)
    })
  }

  ngOnInit(): void {
    console.log("prepared prod build on 15th nov 2024")
    if(this.genesysServie.tokenType ==="genesys")
    {
      if(this.genesysServie._alreadyLogged == false){
        this.setDynamicParameters();
        // Setup Client App
        
        const client= this.platformclient.ApiClient.instance;
        client.setEnvironment(this.pcEnvironment.replace(".login","").replace("/oauth",""));
        client.setPersistSettings(true,this.config.appName)
        const urlParams = new URLSearchParams(window.location.search);
        // client.loginCodeAuthorizationGrant(this.config.clientID,this.config.clientSecret,"OAuth",this.config.premiumAppURL).then(res=>{
        //   console.log(res)
        // })
        client.loginImplicitGrant(this.config.clientID,this.config.wizardUriBase).then(res=>{
          this.genesysServie._alreadyLogged=true;
          this.genesysServie.dataToken = res.accessToken;
          this.router.navigateByUrl('/home');
          this.apiService.getUserdetails().subscribe(s=>{
            if(this.propService.EmailLink !=null){
              window.location.href=this.propService.EmailLink
              //this.router.navigateByUrl(this.propService.EmailLink)
            }
            else{
              this.router.navigateByUrl('/home');
             this.myBrowser()
          }});
    
        }).catch((e) => {
          alert('access_token')
          alert(e)
    
        }); 
      }
    
    }
    
  }

  setRemember(val) {
    this.loginForm.controls.remember.patchValue(val);
  }
  onEnterKeyDown() {
    this.signIn()
  }

  signIn() {
    // event.preventDefault();
    if (this.loginForm.invalid) {
      this.loginForm.controls.email.markAsTouched();
      this.loginForm.controls.password.markAsTouched();
      return;
    }
    const params = this.loginForm.value;
    this.showError = null;

    Auth.signIn(params.email, params.password).then((res: any) => {
      if (res.signInUserSession && res.signInUserSession.idToken) {
        this.showSuccess = true;
        this.user.setToken(res.signInUserSession.idToken.jwtToken);
        localStorage.setItem('UserCred', JSON.stringify({ Username: params.email, Password: params.password }));
        this.apiService.getUserdetails().subscribe(s=>{
          this.router.navigateByUrl('/home');
        });
        this.user.setUserName(params.email);
        this.user.setPassword(params.password);
        this.myBrowser();
      } else {
        this.showError = 'Something went wrong. Try again';
      }
    }, err => {
      this.showError = err.message;
    });
  }
togglePasswordVisibility() {
  this.hidePassword = !this.hidePassword;
}
  setDynamicParameters(){
   // Get Query Parameters
    const urlParams = new URLSearchParams(window.location.search);
    let tempLanguage = urlParams.get(this.config.languageQueryParam);
    let tempPcEnv = urlParams.get(this.config.genesysCloudEnvironmentQueryParam); 
  
    // Language
    this.pcLanguage = tempLanguage || this.genesysServie.getLanguage ||
                this.config.defaultLanguage;
  
   
    this.genesysServie.setLanguage =this.pcLanguage
    // Environment
    
    this.pcEnvironment = tempPcEnv ||
                    this.genesysServie.getAppName
                     ||
                    this.config.defaultPcEnvironment;

    this.genesysServie.setAppName= this.pcEnvironment;   
  }
  myBrowser() { 
    if( window.navigator.userAgent.indexOf("edge") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
        return 'edge';
    }else if(window.navigator.userAgent.indexOf("Firefox") != -1 ){
      this.toastr.success("For better Result please use Google Chrome")
      return 'Firefox'; 
    }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
        return 'Chrome';
    }else if(window.navigator.userAgent.indexOf("Safari") != -1){
      this.toastr.success("For better Result please use Google Chrome")
        return 'Safari';
    }else if(window.navigator.userAgent.indexOf("AppleWebKit") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
         return 'AppleWebKit';
    }else if(window.navigator.userAgent.indexOf("edg") != -1 ) {
      this.toastr.success("For better Result please use Google Chrome")
         return 'edge';
    }
    else {
      this.toastr.success("For better Result please use Google Chrome")
       return 'unknown';

    }

}
}
