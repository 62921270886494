import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CommonComponentsModule } from './common/common.module';
import { SignupComponent } from './signup/signup.component';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { CalendarModule } from 'angular-calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { ForgotAccountComponent } from './forgot-account/forgot-account.component';
import { CookieModule } from 'ngx-cookie';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptor } from './services/http.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragulaModule } from 'ng2-dragula';
import { ToastrModule } from 'ngx-toastr';
import { PaymentRedirectComponent } from './external/payment-redirect/payment-redirect.component';
import { ResetAccountConfirmComponent } from './reset-account-confirm/reset-account-confirm.component';
import { GenesysSetupComponent } from './genesys-setup/genesys-setup.component';
import {MatDialogModule} from '@angular/material/dialog';
// import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';

import {
	MatInputModule,
} from '@angular/material/input';
import{MatIconModule} from '@angular/material/icon';
import {
	MatTooltipModule,
} from '@angular/material/tooltip';
import {
	MatSliderModule,
} from '@angular/material/slider'
import {
	MatListModule,
} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';


// import { DialoboxComponent } from './home/dashboard/dialobox/dialobox.component';
// import { DialoboxComponent } from './home/dashboard/dialobox/dialobox.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SignupComponent,
    VerifyAccountComponent,
    ForgotAccountComponent,
    PaymentRedirectComponent,
    ResetAccountConfirmComponent,
    GenesysSetupComponent,
    
  
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    MatExpansionModule,
        BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatMenuModule,
    FormsModule,
    CommonComponentsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatDialogModule,
		MatInputModule,
		MatTooltipModule,
    MatSliderModule,
		MatListModule,
    MatSelectModule,
    // NgZorroAntdModule,
    AkitaNgRouterStoreModule.forRoot(),
    CookieModule.forRoot(),
    DragulaModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
    
    // CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  providers: [
    // { provide: NZ_I18N, useValue: en_US },
    { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' } },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [
    // CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
