<!-- <script src="https://sdk-cdn.mypurecloud.com/javascript/latest/purecloud-platform-client-v2.min.js"></script>
    <script src="https://sdk-cdn.mypurecloud.com/client-apps/1.3.0/purecloud-client-app-sdk.js"></script>
    
    <script src="https://code.jquery.com/jquery-3.3.1.min.js"></script> -->

<div class="login-container">
    <div class="col s-100 panel-left">
        <div class="banner">
            <img class="bg" src="assets/brand-logo.png">
        </div>
    </div>
    <div class="col s-100 panel-right">
        <div class="form-wrapper animate fade-in">
            <app-heading [title]="'Sign In'"></app-heading>
            <form class="form" (submit)="signIn()" [formGroup]="loginForm" (keydown.enter)="onEnterKeyDown()">
                <app-alert *ngIf="showError" [type]="'danger'">
                    <div content>{{showError}}</div>
                </app-alert>
                <app-alert *ngIf="showSuccess">
                    <div content>You will be redirected shortly...</div>
                </app-alert>
                <app-form-input [label]="'Email'" [error]="loginForm.controls.email">
                    <input type="email" formControlName="email" autocomplete="off">
                </app-form-input>
              <div class="container">
                <div style="position: absolute;right: 10px;margin-top: 38px;z-index: 1;">
                    <mat-icon (click)="togglePasswordVisibility()" style="cursor: pointer;">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>          
                 </div> 
                <div>
                    <app-form-input [label]="'Password'" [error]="loginForm.controls.password">
                    <input type="{{ hidePassword ? 'password' : 'text' }}" formControlName="password" autocomplete="off">  
                </app-form-input>
                 </div>
                   
                </div> 
         <!-- <button  mat-icon-button "> -->
            
        <!-- </button>  -->
                <div class="forgot-user">
                    <a routerLink="/recover">Forgot Password?</a>
                </div>
                <div class="action-bar" >
                    <button class="btn-create" style="margin-right: 0px;" >Sign In</button>
                </div>
            </form>

            <div class="login-link">
                Don't have an account? <a routerLink="/signup">Sign Up</a>
            </div>
        </div>
        <div style="color: #888888;bottom: 0;position: fixed;padding: 28px;padding-top: 0px;">
            This system is intended solely for authorized users in compliance with OneCG's security policies and procedures. Unauthorized use or usage beyond one's authority may result in disciplinary actions by OneCG authorities and/or law enforcement. OneCG assumes no liability for any misuse or personal usage of its information systems and reserves the right to monitor system usage to address abusive situations or security policy breaches.
        </div>
    </div>
</div>
<!-- <script src="../purecloud/scripts/main.js"></script> -->